import logotg from "../../assets/logo_tg.svg";
import location from "../../assets/ico_location.svg"
import phone from "../../assets/ico_phone.svg"

const HowtoBot = props => {

    return (
        <div className='bot-container'>

            <h2 className="mb-30 mr-30">Как прислать нам<br /> вашу Ёлку?</h2>

            <div className="bot-wrapper">
                <p className="bot-text">Сфотографируйте свою БЧБ Ёлку или целую новогоднюю композицию в любимых цветах</p>
                <div className="ico-container">
                    <div className="d-flex mr-10">
                        <img width={40} height={40} src={phone} alt="location" />
                    </div>

                </div>
            </div>

            <div className="bot-wrapper">
                <p className="bot-text">Отправьте фото вашей Ёлки или БЧБ композиции в наш бот в Telegram</p>
                <div className="ico-container">
                    <img width={40} height={40} src={logotg} alt="logo tg" />
                </div>
            </div>

            <div className="bot-wrapper">
                <p className="bot-text">Введите свой город, чтобы мы знали, откуда ваша Ёлка</p>
                <div className="ico-container">
                    <img width={40} height={40} src={location} alt="location" />
                </div>
            </div>


        </div>
    )
}

export default HowtoBot;
