
const updateData = (state, action) => {

    if (state === undefined) {
        return {
            areas: [],
            filtered: [],
            selected: [],
            counters: {count: 0},

            areasLoading: false,
            selectedloading: false
        }
    }

    switch (action.type) {
        case 'FETCH_DATA_REQUEST':
            return {
                ...state.data,
                selectedloading: true,
            };

        case 'FETCH_DATA_SUCCESS':
            return {
                ...state.data,
                selected: action.payload,
                selectedloading: false,
            };

        case 'FETCH_DATA_FAILURE':
            return {
                selected: [],
                selectedloading: false,
                error: action.payload
            };


        case 'FETCH_AREAS_REQUEST':
            return {
                ...state.data,
                areasLoading: true
            };

        case 'FETCH_AREAS_SUCCESS': {

            const payload = action.payload;
            const splitData = [];

            payload.forEach(item => {
                const existed = splitData.find(el => el.area === item.area);
                if (existed) {
                    existed.images.push(item)
                } else {
                    splitData.push({
                        id: (splitData.length + 1),
                        area: item.area,
                        images: [item]
                    })
                }
            })

            return {
                ...state.data,
                areas: payload,
                filtered: splitData,
                counters: {count: payload.length},
                areasLoading: false
            }
        }

        default:
            return state.data;
    }
};

export default updateData;
