
const SERVER_URL = process.env.REACT_APP_API_URL

export default class APIService {

  _selected = [
    {
      title: "Светлана Тихановская",
      type: "video",
      preview: "https://d28xp61908rgs.cloudfront.net/ST.png",
      url: "https://d28xp61908rgs.cloudfront.net/st.mp4"
    },
    {
      title: "Павел Латушко",
      type: "video",
      preview: "https://d28xp61908rgs.cloudfront.net/PL.png",
      url: "https://d28xp61908rgs.cloudfront.net/6d030f5e-23ed-4645-888f-77be99fa411c.mp4"
    },
    {
      title: "Александра Герасименя",
      type: "video",
      preview: "https://d28xp61908rgs.cloudfront.net/AG.png",
      url: "https://d28xp61908rgs.cloudfront.net/1ffd2879-2d61-4f62-aa61-c619bdb7d746.mp4"
    },
    {
      title: "Вадим Прокопьев",
      type: "video",
      preview: "https://d28xp61908rgs.cloudfront.net/VP.png",
      url: "https://d28xp61908rgs.cloudfront.net/66c59d8b-03a5-4ac8-bade-af1a09f2d2e0.mp4"
    },
    {
      title: "Екатерина Снытина",
      type: "video",
      preview: "https://d28xp61908rgs.cloudfront.net/ES.png",
      url: "https://d28xp61908rgs.cloudfront.net/es.mp4"
    },
    {
      title: "Максим Богрецов",
      type: "video",
      preview: "https://d28xp61908rgs.cloudfront.net/MB.png",
      url: "https://d28xp61908rgs.cloudfront.net/cedb02a3-0987-4469-9078-8677324c473e.mp4"
    },
    {
      title: "Nizkiz",
      type: "video",
      preview: "https://d28xp61908rgs.cloudfront.net/ng.png",
      url: "https://d28xp61908rgs.cloudfront.net/nz.mp4"
    },
  ]
  _areas = [
    {
      area: "Могилевская обл",
      url: "https://d28xp61908rgs.cloudfront.net/ST.png",
    },
    {
      area: "г. Минск",
      url: "https://d28xp61908rgs.cloudfront.net/PL.png",
    },
    {
      area: "Могилевская обл",
      url: "https://d28xp61908rgs.cloudfront.net/AG.png",
    },
    {
      area: "Минская обл",
      url: "https://d28xp61908rgs.cloudfront.net/VP.png",
    },
    {
      area: "Минская обл",
      url: "https://d28xp61908rgs.cloudfront.net/ES.png",
    },
    {
      area: "Гродненская обл",
      url: "https://d28xp61908rgs.cloudfront.net/MB.png",
    },
    {
      area: "Гомельская обл",
      url: "https://d28xp61908rgs.cloudfront.net/ng.png",
    },
  ]


  getAsyncFetch = (urladdon, requestOptions={}, serverToLoad=SERVER_URL) => {

    console.log("requesting data from: " + (serverToLoad + urladdon));

    return fetch(serverToLoad + urladdon, requestOptions)
        .then(res => {
          if (res.status === 200 || res.status === 201) {
            return res.json()
          } else {
            Promise.reject(res.statusText);
          }
        })
        .then(res => {
          if(res === undefined) {
            Promise.reject("Произошла ошибка загрузки данных. Мы обязательно исправим ее в ближайшее время");
          }
          return Promise.resolve(res.result)
        })
        .catch(error => {
          Promise.reject(error);
        })
  }

  getSelected = async () => {
    return this._selected;
  };

  getAreas = async () => {
    //return this._areas;

    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    };

    return this.getAsyncFetch("/activity", requestOptions);
  };


}
