import { HashLink } from 'react-router-hash-link';

import star1 from "../../assets/star1.svg";
import star2 from "../../assets/star2.svg";
import star3 from "../../assets/star3.svg";
import logotg from "../../assets/logo_tg.svg";
import {connect} from "react-redux";

const Header = props => {

    const counter = (props.counters && props.counters.count) ? props.counters.count : 0

    const liItems = [];
    Array.from(counter.toString()).map((item, index) =>
        liItems.push(<li key={index}>{item}</li>))

    const counterComponent = liItems.length > 0 ?
        <ul>{liItems}</ul> : null;

    return (


        <div className='header-wrapper'>
            <div className="header-container">
                <div className="mt-30 d-flex-wrap-sb">
                    <div>
                        <h1 className="mb-10 mr-30">Беларусь<br/>нарядная</h1>
                        <h3 className="mb-20 mr-30">Конкурс новогодних ёлок по Беларуси и зарубежью</h3>
                    </div>
                    <div className="counter">
                        {counterComponent}
                        <h2>ёлок прислано</h2>
                    </div>
                </div>
                <div>
                    <div className="separator" />
                    <div className="mt-30 mb-30 d-flex-wrap-sb">

                        <div className="header-intro">
                            <div className="w-300 mr-20 mb-30">
                                <div className="d-flex">
                                    <div className="mr-10">
                                        <img src={star1} alt="star 1"/>
                                    </div>
                                    <p className="h-100">Нарядите свою БЧБ Ёлку или оформите новогоднюю композицию</p>
                                </div>
                                <div className="pl-80">
                                    <HashLink to="/#about"><button>Подробнее о конкурсе</button></HashLink>
                                </div>
                            </div>
                        </div>

                        <div className="header-intro">
                            <div className="w-300 mr-20 mb-30">
                                <div className="d-flex">
                                    <div className="mr-10">
                                        <img src={star2} alt="star 1"/>
                                    </div>
                                    <p className="h-100">Отправьте фото в наш бот в Telegram и укажите ваш город</p>
                                </div>
                                <div className="pl-80">
                                    <a href="https://t.me/xmas_tree_by_bot" target="_blank"  rel="noreferrer"><img src={logotg} alt="logo tg" /></a>
                                   </div>
                            </div>
                        </div>

                        <div className="header-intro">
                            <div className="w-300">
                                <div className="d-flex">
                                    <div className="mr-10">
                                        <img src={star3} alt="star 1"/>
                                    </div>
                                    <p className="h-100">Голосуйте в телеграм-чатах вашего города за самую красивую новогоднюю ёлку!</p>
                                </div>
                                <div className="pl-80">
                                    <HashLink to="/#map"><button>Проголосовать! </button></HashLink>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

        </div>
    )
}

const mapStateToProps = ({ data: { counters }}) => {
    return { counters };
};

export default connect(mapStateToProps)(Header);
