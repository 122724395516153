import {connect} from "react-redux";
import {useState} from "react";
import AreaDreams from "./area-dreams";

const AreasDreams = props => {

    const { areas, filtered } = props;

    const [currentId, setCurrentId ] = useState(null)

    if (!areas || !areas.length) return null;

    return (
        <div>
            {!currentId &&
                <div className='selected-container bg-tree'>
                    <h2 className="mb-30 wp-100">Ёлки по регионам</h2>
                    {
                        filtered.map((item, index) => {
                            return (
                                <div key={index} className="thumbnail thumbnail-red-border" onClick={() => setCurrentId(index + 1)}>
                                    <img src={item.images[0].url} alt="thumbnail"/>
                                    <div className="labelBg thumbnail-red-bg">{item.area}</div>
                                </div>
                            )
                        })
                    }
                </div>
            }
            {currentId &&
                <div><AreaDreams filtered={filtered} id={ currentId } onExit={() => setCurrentId(null)} /></div>

            }

        </div>
    )
}

const mapStateToProps = ({ data: { areas, filtered }}) => {
    return { areas, filtered };
};

export default connect(mapStateToProps)(AreasDreams);
