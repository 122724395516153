import ReactPlayer from 'react-player'

const BackgroundVideo = props => {

    return (
        <div className='player-wrapper'>
                <ReactPlayer width="1920"
                             height="1080"
                             playing={true}
                             loop={true}
                             controls={false}
                             muted={true}
                             url='https://moydvor-documents.s3.eu-north-1.amazonaws.com/video/xmas.mp4' />
        </div>
    )
}

export default BackgroundVideo;
