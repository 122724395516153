
const FooterBottom = props => {

    return (
        <div className='bottomfooter-container'>

            <p><a href="https://t.me/xmas_tree_by_bot" target="_blank" rel="noreferrer">Прислать Ёлку в Telegram</a></p>

        </div>
    )
}

export default FooterBottom;
