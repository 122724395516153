
import APIService from "../services/api-service";

const selectedRequested = () => {
    return {
        type: 'FETCH_DATA_REQUEST'
    }
};

const selectedLoaded = (data) => {
    return {
        type: 'FETCH_DATA_SUCCESS',
        payload: data
    };
};

const selectedError = (error) => {
    return {
        type: 'FETCH_DATA_FAILURE',
        payload: error
    };
};

const areasRequested = () => {
    return {
        type: 'FETCH_AREAS_REQUEST'
    }
};

const areasLoaded = (data) => {
    return {
        type: 'FETCH_AREAS_SUCCESS',
        payload: data
    }
}

const fetchSelected = (dispatch) => (...args) => {
    const apiService = new APIService();
    dispatch(selectedRequested());
    apiService.getSelected()
        .then((data) => dispatch(selectedLoaded(data)))
        .catch((err) => dispatch(selectedError(err)));
};

const fetchAreas = (dispatch) => (...args) => {
    const apiService = new APIService();
    dispatch(areasRequested());
    apiService.getAreas()
        .then((data) => dispatch(areasLoaded(data)))
        .catch((err) => dispatch(selectedError(err)));
};



export {
    fetchSelected, fetchAreas
};
