import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import { fetchSelected, fetchAreas } from '../../actions';
import BackgroundVideo from "../background-video";
import {Switch, Route, withRouter} from "react-router-dom"

import "./main.css";
import "./shortcuts.css";
import Header from "../header";
import AboutProject from "../about-project";
import HowtoBot from "../howto-bot";
import FooterBottom from "../footer-bottom";
import AreasDreams from "../areas-dreams";

const App = props => {

  const { fetchSelected, fetchAreas, areas } = props;

  useEffect(() => {
    fetchSelected();
    fetchAreas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!areas || !areas ) return null

  return (
      <div className="wrapper">
        <BackgroundVideo />
        <Switch>
          <Route path='/' exact>
            <div className="container">
              <div className="container-fixed-width">
                <Header />
                <AboutProject />
                <HowtoBot />
                <AreasDreams />

                <FooterBottom />
              </div>
            </div>
          </Route>
        </Switch>
      </div>
  );

}

const mapStateToProps = ({ data: { areas }}) => {
  return { areas };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSelected: fetchSelected(dispatch),
    fetchAreas: fetchAreas(dispatch),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
