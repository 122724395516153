import React from 'react';
import { SRLWrapper } from "simple-react-lightbox";
import {FiChevronLeft} from "react-icons/fi";
import useWindowDimensions from "../../hooks/useWindowDimensions";

const options = {

};


const AreaDreams = props => {

    const { filtered, id, onExit } = props;

    const { width } = useWindowDimensions();

    if (!filtered || !id) return null

    const data = filtered.find(item => item.id === id)

    return (
        <div className='selected-container white fcblack'>
            <div className='d-flex-ac mb-30 wp-100'>
                <FiChevronLeft className='enabledClick' color='#000' size={32} onClick={onExit} />
                <h2 className="ml-5">{data.area}</h2>
            </div>
            <SRLWrapper options={options}>
                <div className='d-flex-wrap'>
                {
                    data.images.map((item, index) => {
                        return (
                            <a key={index} href={item.url} className='mr-20 mb-20'>
                                <img width={width < 450 ? width-40 : 300} height={300} style={{objectFit: 'cover'}} src={item.url} alt=""/>
                            </a>
                            )
                        })
                }
                </div>
            </SRLWrapper>
        </div>
    )
}

export default AreaDreams;
