
const AboutProject = props => {

    return (
        <div id="about" className='about-container'>
            <h2 className="mb-30">Конкурс «Беларусь нарядная»</h2>
            <div className="mb-30 wp-95">
                <p>2021 год навсегда останется в истории нашей страны годом активной борьбы и несогласия с диктатурой. Мы все, каждый из нас продолжаем сражаться за Новую и Свободную Беларусь. Мы ни на день не забываем о несправедливости, которая происходит в нашем общем доме.</p>

                <p className='mt-20'>Но при этом, мы можем позволить себе находить поводы для радости, чтобы набраться сил, помечтать и найти вдохновение для завтрашнего дня.</p>

                <p className='mt-20'>Конкурс новогодних БЧБ ёлок - это отличная возможность проявить креативность в выражении своей гражданской позиции. Нас могут судить за бчб шторы и белые листки на окнах. Но они не смогут запретить нам украшать наши дома и ёлки в бело-красно-белое.</p>

                <p className='mt-20'>И пусть знают они и помним мы с вами. Нас много.</p>

                <p className='mt-20'>И в подтверждении этому данная инициатива, которая получилась благодаря объединению многих разных людей. Мы не станем перечислять себя, потому что это не важно. Важно, что мы все на одной стороне.</p>

                <p className='mt-20'>С Новым годом!</p>

                <p className='mt-20'>Жыве Беларусь!</p>

            </div>

        </div>
    )
}

export default AboutProject;
