import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import App from "./components/app";
import ErrorBoundry from "./components/error-boundry";
import { Provider } from 'react-redux';
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import store from './store';
import SimpleReactLightbox from 'simple-react-lightbox'

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

ReactDOM.render(
    <Provider store={store}>
        <ErrorBoundry>
            <SimpleReactLightbox>
                <Router>
                    <ScrollToTop />
                    <App />
                </Router>
            </SimpleReactLightbox>
        </ErrorBoundry>
    </Provider>,
    document.getElementById('root')
);
